import { createRoot } from 'react-dom/client'
import { useRoute, useLocation } from 'wouter'
import './styles.css'
import { Loader } from '@react-three/drei'
import myLogo from '../src/Etherion_logo_notext.png'; 
// change bottom left logo here
import { App } from './App'
import CodropsFrame from "./CodropsFrame";

function Root() {
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  
  return (
    <>
      <Loader
        containerStyles={{
          background: "#ecf3f6",
          top: "auto",
          bottom: 0,
          height: "100%",
          width: "100%",
          position: "fixed",
          justifyContent: "center", // Center content horizontally
          alignItems: "center" // Center content vertically
        }}
        innerStyles={{ background: "transparent", width: "40vh", height: "105px" }}
        barStyles={{ background: 'linear-gradient(to right bottom, black, gray)', height: "30%", borderRadius: "100px" }}
        dataStyles={{ color: 'ff4d4d', fontSize: '14px', fontFamily: "Poppins, sans-serif" }}
        dataInterpolation={(p) => `Try to Scroll and Drag ${p.toFixed(2)}%`}
        initialState={(active) => active}
      />
      <CodropsFrame />
      <App />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <a className='footerLeft' href="https://Etherion.io/" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
          
          Etherion
        </a>
        <div className='footerRight' style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px', textAlign:'right'}}>3D web experiences <br/>no/low-code protocol</div>
        <img src={myLogo} alt="Logo" style={{ position: 'absolute', bottom: 43, left: 50, width: 28 }} />
        <a  className={'backButton'} href="#" onClick={() => setLocation('/')}>
          {params ? '< back' : 'click to enter portal'}
        </a>
      </div>{' '}
    </>
  )
}

createRoot(document.getElementById('root')).render(<Root />)