

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('./glb/play.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 1.89]} rotation={[Math.PI / 2, 0, 0]} scale={0.786}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.play002_1.geometry}
          material={materials.play}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.play002_2.geometry}
          material={materials['Material.004']}
        />
      </group>
      <mesh castShadow receiveShadow geometry={nodes.play.geometry} material={materials.play} />
    </group>
  )
}

useGLTF.preload('./glb/play.glb')