import * as THREE from 'three'
import { TextureLoader } from 'three';
import { forwardRef, useState, useEffect, Suspense, useRef } from 'react'
import { Canvas, useFrame, useLoader, useThree, } from '@react-three/fiber'
import { MeshDistortMaterial, GradientTexture, Float, MeshReflectorMaterial, Environment, useTexture, roundedPlaneGeometry, Text3D, Center, useCursor } from '@react-three/drei'
import { EffectComposer, GodRays, Bloom } from '@react-three/postprocessing'
import { easing } from 'maath'
import { CursorClickIcon } from '@heroicons/react/outline';


function Etherion({ margin = 0.5 }) {
    const { width, height } = useThree((state) => state.viewport)
    const ref = useRef()
    const [hovered, hover] = useState(false)
    useCursor(hovered);

    useFrame(() => {
        if (ref.current) {
            ref.current.rotation.y += 0.01; // Example: Rotate the group
        }
    });
    return (
        <>
            <Environment
                background
                backgroundBlurriness={15}
                backgroundIntensity={0.1}
                backgroundRotation={[0, Math.PI / 2, 0]}
                // environmentIntensity={12}
                environmentRotation={[0, Math.PI / 2, 0]}
                resolution={1024}
                files={
                    "/textures/kloofendal_48d_partly_cloudy_puresky_2k_dark.hdr"
                }
            />
            <Suspense fallback={null}>
                <group onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} position={[0, 0, 0]} onClick={MoveToAboutUs}>
                    <group scale={[0.5, 0.5, 0.5]}>
                        <Screen />
                    </group>

                    <group position={[-0.4, -1.5, -5]}>
                        <Center right >
                            <ambientLight intensity={0.6} />
                            <Text3D
                                curveSegments={12}
                                bevelEnabled
                                bevelSize={0.001}
                                bevelThickness={0.001}
                                height={0.01}
                                lineHeight={0.65}
                                letterSpacing={-0.001}
                                size={0.15}
                                font="../fonts/Inter_Bold.json">
                                {'Click Me'}
                                <meshStandardMaterial
                                    color={'white'}
                                />
                            </Text3D>
                        </Center>
                    </group>
                </group>
                <Environment preset="city" />
            </Suspense>
        </>
    )
}

export default Etherion



const Emitter = forwardRef((props, ref) => {
    const imageTexture = useLoader(TextureLoader, '../images/whitepaper.png'); // Replace with the path to your image

    return (

        <mesh ref={ref} position={[0, 0, -16]} {...props}>
            <roundedPlaneGeometry args={[21, 10, 0.5]} /> {/* Adjust the radius for rounded corners */}
            <meshBasicMaterial map={imageTexture} />
        </mesh>
    );
});


function Screen() {
    const [material, set] = useState()
    return (
        <>
            <Emitter ref={set} />
        </>
    )
}

function MoveToAboutUs() {
    const space = { name: "LivingRoom" };

    window.location.href = `https://etherion.io/WhitePaper/`;
}

{/* <MeshReflectorMaterial
blur={[300, 100]}
resolution={2048}
mixBlur={1}
mixStrength={74}
roughness={1}
depthScale={1.2}
minDepthThreshold={1.4}
maxDepthThreshold={1.4}
color="D8D8D8"
metalness={0.4}
/> */}