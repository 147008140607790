import { useGLTF, useAnimations } from '@react-three/drei'
import { useRef, useEffect, useState } from 'react';


export default function Yatsiu(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/glb/yatsiu_model_space.glb')
  const { actions, ref, names } = useAnimations(animations, group)
  function playAnims(actionArray) {
    actions[actionArray].timeScale = 1.2;
    actions[actionArray].reset().fadeIn(0.5).play();
  }

  useEffect(() => {
    names.forEach(playAnims)
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature002" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <skinnedMesh
            name="bottom"
            geometry={nodes.bottom.geometry}
            material={materials.SILVER}
            skeleton={nodes.bottom.skeleton}
          />
          <skinnedMesh
            name="jacket"
            geometry={nodes.jacket.geometry}
            material={materials.SILVER}
            skeleton={nodes.jacket.skeleton}
          />
          <skinnedMesh
            name="left_shoe"
            geometry={nodes.left_shoe.geometry}
            material={materials['shoes1.001']}
            skeleton={nodes.left_shoe.skeleton}
          />
          <skinnedMesh
            name="right_shoe"
            geometry={nodes.right_shoe.geometry}
            material={materials['shoes1.001']}
            skeleton={nodes.right_shoe.skeleton}
          />
          <skinnedMesh
            name="YS002"
            geometry={nodes.YS002.geometry}
            material={materials.SILVER}
            skeleton={nodes.YS002.skeleton}
          />
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/glb/yatsiu_model_space.glb')