import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa'; // Ensure this is correct if you're using react-icons

function MoveToSlides() {
  location.href = 'https://memotics.io/Slides/';
}

function MoveToAboutUs() {
  location.href = 'https://memotics.io/AboutUs/';
}

function MoveToMDS() {
  location.href = 'https://memotics.io/MDS/';
}

function MoveToTour() {
  location.href = 'https://memotics.io/Tour/';
}

function MoveToWP() {
  location.href = 'https://etherion.io/WhitePaper/';
}

function MoveToMemotics() {
  location.href = 'https://memotics.io/';
}

const Dropdown = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      <button className="dropbtn" onClick={handleOpen}>
        Menu <i class="fa fa-bars"></i>
      </button>
      {open ? (
        <ul className="menu">
          {/* <li className="menu-item">
            <button onClick={MoveToMDS}>MDS</button>
          </li> */}
    
          <li className="menu-item">
            <button onClick={MoveToMemotics}>Memotics</button>
          </li>
          <li className="menu-item">
            <button onClick={MoveToSlides}>Pitch</button>
          </li>
          <li className="menu-item">
            <button onClick={MoveToWP}>WhitePaper</button>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

export default Dropdown;
