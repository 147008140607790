import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { useCursor, MeshPortalMaterial, CameraControls, Gltf, Text, Preload, Environment, useTexture, Float, Loader } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing, geometry } from 'maath'
import { suspend } from 'suspend-react'
import { CuboidCollider, BallCollider, Physics, RigidBody } from '@react-three/rapier'
import AeroplaneModel from "./AeroplaneModel";
import OrgModel4 from "./OrgModel4";
import { InteractionMDS } from './InteractionMDS';
import { RoomBackground } from './LivingRoom/LivingRoomApp';
import LaptopApp from './Laptop';
import LightScreenApp from './LightScreen';
import Etherion from './Etherion';
import VtourApp from './Vtour';
import CodropsFrame from "./CodropsFrame";
import Yatsiu from './Yatsiu'


{/* <Gltf src="../glb/fiesta_tea-transformed.glb" position={[0, -2, -3]} /> */ }

extend(geometry)
const regular = import('@pmndrs/assets/fonts/inter_regular.woff')
const medium = import('@pmndrs/assets/fonts/inter_medium.woff')

export const App = () => {
  return (

    <Canvas camera={{ fov: 75, position: [0, 0, 20] }} eventSource={document.getElementById('root')} eventPrefix="client">
      <color attach="background" args={['#f0f0f0']} />
      <Frame id="01" name={`Demo\nUse Case`} author="Etherion" bg="#e4cdac" position={[-1.15, 0, 0]} rotation={[0, 0.5, 0]}>
        <VtourApp />
      </Frame>
      <Frame id="02" name={`White\nPaper`} author="Etherion">
        <Etherion />
      </Frame>
      <Frame id="03"  name={`NFT\nSpace`} author="Etherion" bg="#d1d1ca" position={[1.15, 0, 0]} rotation={[0, -0.5, 0]}>
        {/* <LightScreenApp /> */}
        <InteractionMDS />
        <group scale={0.080} position={[4,-3,-8]} rotation={[0,Math.PI*-0.1,0]}>
        <Yatsiu/>
        </group>
        {/* <LaptopApp /> */}
      </Frame>
      <Rig />
      <AeroplaneModel />
      <SpinModel />
      {/* <InteractionMDS /> */}
      {/* <OrgModel4 rotation-y={Math.PI * 0.25} position={[-5, 0, 0]} scale={2}/> */}
      <Environment
        background
        backgroundBlurriness={15} // optional blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
        backgroundIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
        backgroundRotation={[0, Math.PI / 2, 0]} // optional rotation (default: 0, only works with three 0.163 and up)
        environmentIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
        resolution={1024}
        files={
          "/textures/kloppenheim_06_puresky_2k.hdr"
        }
      />
      <Preload all />
    </Canvas>
  )
}

function Frame({ id, name, author, bg, width = 1, height = 1.61803398875, children, ...props }) {
  const portal = useRef()
  const [, setLocation] = useLocation()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  useCursor(hovered)
  useFrame((state, dt) => easing.damp(portal.current, 'blend', params?.id === id ? 1 : 0, 0.2, dt))
  return (
    <group {...props}>
      <Text font={suspend(medium).default} color={'#ffffff'} fontSize={0.2} anchorY="top" anchorX="left" lineHeight={0.8} position={[-0.435, 0.715, 0.01]} material-toneMapped={false}>
        {name}
      </Text>
      <Text font={suspend(regular).default} color={'#ffffff'} fontSize={0.1} anchorX="right" position={[0.4, -0.659, 0.01]} material-toneMapped={false}>
        /{id}
      </Text>
      <Text font={suspend(regular).default} color={'#ffffff'} fontSize={0.04} anchorX="right" position={[0.0, -0.677, 0.01]} material-toneMapped={false}>
        {author}
      </Text>
      <mesh name={id} onClick={(e) => (e.stopPropagation(), setLocation('/item/' + e.object.name))} onPointerOver={(e) => hover(true)} onPointerOut={() => hover(false)}>
        <roundedPlaneGeometry args={[width, height, 0.1]} />
        <MeshPortalMaterial ref={portal} events={params?.id === id} side={THREE.DoubleSide}>
          <color attach="background" args={[bg]} />
          {children}
        </MeshPortalMaterial>
      </mesh>
    </group>
  )
}

function Rig({ position = new THREE.Vector3(0, 0, 2), focus = new THREE.Vector3(0, 0, 0) }) {
  const { controls, scene } = useThree()
  const [, params] = useRoute('/item/:id')
  useEffect(() => {
    const active = scene.getObjectByName(params?.id)
    if (active) {
      active.parent.localToWorld(position.set(0, 0.5, 0.25))
      active.parent.localToWorld(focus.set(0, 0, -2))
    }
    controls?.setLookAt(...position.toArray(), ...focus.toArray(), true)
  })
  return <CameraControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1} maxDistance={5} minDistance={1} />
}


const SpinModel = () => {
  const boxRef = useRef();
  useFrame((state, delta) => {
    boxRef.current.rotation.y -= 0.01;
    if (boxRef.current.rotation.y <= -(Math.PI * 2)) {
      boxRef.current.rotation.y = 0;
    }
  });

  return (
    <>
      <mesh ref={boxRef} rotation-y={Math.PI * 0.25} position={[-18, -3, 0]} scale={5}>
        {/* <Suspense fallback=""> */}
        <OrgModel4 />
        {/* </Suspense> */}
      </mesh>
    </>
  )
}

