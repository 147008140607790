import * as THREE from 'three'
import React, { Suspense, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader, useThree, } from '@react-three/fiber'
import { useCursor, MeshPortalMaterial, CameraControls, Gltf,Html, Text, Preload, Environment, useTexture, Float,Text3D, Center } from '@react-three/drei'
import HeroPage from './HeroPage'
import LightScreenApp from './LightScreen';
import TourBall from './TourBall'

const VtourApp = () => {
    const livingRoomTexture = useTexture("../images/VirtualTour.png");
    const videoSphereRef = useRef();
    const [modelAction, setModelAction] = useState("Waiting");
    const pngTexture = useTexture("../images/VirtualTour.png")
    const moveToTour = () => {
        window.location.href = 'https://memotics.io/Tour/';
    };

    return (
        <>

            <Environment
                background
                backgroundBlurriness={15}
                backgroundIntensity={1}
                backgroundRotation={[0, Math.PI / 2, 0]}
                environmentIntensity={1}
                resolution={1024}
                files="/textures/kloppenheim_06_puresky_2k.hdr"
            />
            <mesh scale={[-90, 90, 90]} ref={videoSphereRef}>

                <sphereGeometry args={[1, 32, 32]} />
                <meshStandardMaterial
                    color="white"
                    side={THREE.BackSide}
                    map={livingRoomTexture}
                    emissive="white"
                    emissiveIntensity={0.01}
                />
            </mesh>

            <Float
                floatIntensity={2}
                speed={2}>
                <group position={[0, -0.4, -10]} scale={2} onClick={MoveToTour}>
                <TourBall />
                </group>
            </Float>
        </>
    );
};

export default VtourApp;


function MoveToTour() {
    const space = { name: "LivingRoom"};

    window.location.href = `https://memotics.io/Tour/`;
}