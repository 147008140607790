
  import * as THREE from 'three'
  import { useRef, useReducer, useMemo, Suspense } from 'react'
  import { Canvas, useFrame } from '@react-three/fiber'
  import { useGLTF, MeshTransmissionMaterial, Environment, Lightformer, Html } from '@react-three/drei'
  import { CuboidCollider, BallCollider, Physics, RigidBody } from '@react-three/rapier'
  import { EffectComposer, N8AO } from '@react-three/postprocessing'
  import { easing } from 'maath'
  import { useInView } from "react-intersection-observer";
  
  const accents = ['#ff4060', '#20ffa0', '#ffcc00']
  const shuffle = (accent = 0) => [
    { color: '#444', roughness: 0.1 },
    { color: '#444', roughness: 0.75 },
    { color: '#444', roughness: 0.75 },
    { color: 'white', roughness: 0.1 },
    { color: 'white', roughness: 0.75 },
    { color: 'white', roughness: 0.1 },
    { color: accents[accent], roughness: 0.1, accent: true },
    { color: accents[accent], roughness: 0.75, accent: true },
    { color: accents[accent], roughness: 0.1, accent: true }
  ]
  
  
  export function InteractionMDS(props) {
    const [accent] = useReducer((state) => ++state % accents.length, 0);
    const connectors = useMemo(() => shuffle(accent), [accent]);

  
    return (
      <>
       <Environment
                background
                backgroundBlurriness={15}
                backgroundIntensity={0.1}
                backgroundRotation={[0, Math.PI / 2, 0]}
                // environmentIntensity={12}
                environmentRotation={[0, Math.PI / 2, 0]}
                resolution={1024}
                files={
                    "/textures/kloofendal_48d_partly_cloudy_puresky_2k.hdr"
                }
            />
        <group position={[-40, 15, -40]}>
        <Physics gravity={[0, 0, 0]}>
          <Pointer />
          {connectors.map((props, i) => <Connector key={i} {...props} />)}
          <Connector position={[10, 10, 5]}>
            <Model>
              <MeshTransmissionMaterial clearcoat={1} thickness={0.1} anisotropicBlur={0.1} chromaticAberration={0.1} samples={8} resolution={512} />
            </Model>
          </Connector>
        </Physics>
      </group>
      </>
    );
  }
  function Connector({ position, children, vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread, accent, ...props }) {
    const api = useRef()
    const pos = useMemo(() => position || [r(10), r(10), r(10)], [])
    useFrame((state, delta) => {
      delta = Math.min(0.1, delta)
      api.current?.applyImpulse(vec.copy(api.current.translation()).add(new THREE.Vector3(0, 0, 30)).negate().multiplyScalar(30)) // change position of the interaction game
    })
    return (
      <RigidBody linearDamping={10} angularDamping={1} friction={0.1} position={pos} ref={api} colliders={false}>
        <CuboidCollider args={[1.14, 3.81, 1.14]} />
        <CuboidCollider args={[3.81, 1.14, 1.14]} />
        <CuboidCollider args={[1.14, 1.14, 3.81]} />
        {children ? children : <Model {...props} />}
        {accent && <pointLight intensity={4} distance={2.5} color={props.color} />}
      </RigidBody>
    )
  }
  
  function Pointer({ vec = new THREE.Vector3() }) {
    const ref = useRef()
    useFrame(({ mouse, viewport }) => {
      ref.current?.setNextKinematicTranslation(vec.set(((mouse.x * viewport.width) * 2), ((mouse.y * viewport.height) * 0.5), -30)) // anything u change in line63 ((mouse.y * viewport.height) * 2) you have to change here position with "-"" value.
    })
    return (
      // change mouse interaction
      <RigidBody position={[0, 0, 0]} type="kinematicPosition" colliders={false} ref={ref}> 
        <BallCollider args={[5]} />
      </RigidBody>
    )
  }
  
  function Model({ children, color = 'white', roughness = 0, ...props }) {
    const ref = useRef()
    const { nodes, materials } = useGLTF('../Models/c-transformed.glb')
    useFrame((state, delta) => {
      easing.dampC(ref.current.material.color, color, 0.2, delta)
    })
    return (
      <mesh ref={ref} castShadow receiveShadow scale={30} geometry={nodes.connector.geometry}>
        <meshStandardMaterial metalness={0.2} roughness={roughness} map={materials.base.map} />
        {children}
      </mesh>
    )
  }
  